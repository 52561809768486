import React from "react"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <div>
    <SEO
      title="Privacy Policy"
      keywords={[`youpal`, `youpal group`, `youmedico`, `Privacy Policy`]}
    />
    <div className="bgImageDiv"></div>
    <section className="container privacyPage youMedicoMain">
      <div className="col">
        <div className="header">
          <h2 className="headerTitle">Privacy Policy</h2>
        </div>
        <div className="mainBody col">
          <h4>Introduction</h4>
          <p>
          Youpal SL as an owner of “Youmedico.com” is providing you with this policy to clearly state how and why we use your personal data. It will also give you the options that are at your disposal in controlling the personal data shared with us.
          </p>
          <p className="text-center">
            <strong>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY AND CONTACT US WITH ANY QUESTIONS OR CONCERNS ABOUT OUR PRIVACY PRACTICES
            </strong>
          </p>
          <h4>Definition</h4>
          <p>
          “Personal Data” or “Personal Information” means any information that identifies or can be used to identify an individual, directly or indirectly, including, but not limited to, name, email address and other personal information.
          </p>
          <p>
          “Processing” of Personal Information means any operation or set of operations which is performed upon Personal Information, whether or not by automated means, such as collection, recording, organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure or dissemination, and erasure or destruction.
          </p>
          <p>
          “Controller” is a person or organization which, alone or jointly with others, determines the purposes and means of the processing of Personal Information.
          </p>
          <p>
          “Users” is a person or organization that is authorized to use Youmedico.
          </p>
          <p>
          “Youmedico” or “Website” or “Platform” or “us” or “we” means a Youpal SL, company registered and acting under the law of Spain, registry code B01619071, with registered address CALLE VIRGEN DE LA LUZ, S/N 11140, CONIL DE LA FRONTERA,  Spain.
          </p>
          <h4>Who are we?</h4>
          <p>
          Youmedico is committed to safeguarding the privacy of our Users. Any and all data submitted to Youmedico will be held and handled in accordance with GDPR regulations and this Privacy Policy.
          </p>
          <p>
          Our registered office address is CALLE VIRGEN DE LA LUZ, S/N 11140, CONIL DE LA FRONTERA,  Spain and is the address to which all formal communication should be addressed. 
          </p>
          <p>
          All formal communication and relevant enquiries related to this Privacy Policy and Personal Data Protection matters should be addressed to our Data Protection Officer - Oleh Chuchman, on the following e-mail: oleh.ch@youpal.se. 
          </p>
          <p>Our website address is Youmedico.com</p>

          <h4>What information do we collect? </h4>

          <p>
          Youmedico avoids collecting unnecessary personal data and follows the “data minimization” principle for data processing and retention.
          </p>
          <p>Our dealings with our Users are based on:</p>
          <ul>
            <li>
              Contractual dealings in form of public offer as per Terms &
              Conditions on the website
            </li>

            <li>
              User’s explicitly consent for personal data processing in the form
              of a declaration (signed electronically) as a mandatory part of
              contact us for.
            </li>
          </ul>
          <p>
          You hereby explicitly state that you provide authorization for Youmedico to use the personal data to fulfil obligations in respect of the scope of terms of use.
          </p>
          <p>
          There is limited list of personal information from you as a User of Youmedico that we may collect:
          </p>
          <ul>
            <li>
              Internet protocol (IP) address used to connect your computer to
              the Internet;
            </li>
            <li>e-mail address;</li>
            <li>country; and</li>
            <li>first and last name.</li>
          </ul>
          <h4>How do we use personal information?</h4>
          <p>We not only respect your privacy - we also want to secure it. </p>
          <p>We use your information strictly according to the Terms & Conditions and Privacy Policy on the website and for one of the following purposes: </p>
          <ul>
            <li>personalization of content, business information or user experience;</li>
            <li>responding to your requests;</li>
            <li>delivering marketing and events communication;</li>
            <li>carrying out polls and surveys;</li>
            <li>internal research and development purposes;</li>
            <li>providing Youmedico services;</li>
            <li>legal obligations (eg prevention of fraud);</li>
            <li>meeting internal audit requirements.</li>
          </ul>
          <p>Also we inform you about cases, when the third person can request from us information your personal information:</p>
          <ul>
            <li>in response to lawful requests by public authorities, including to meet legitimate national security or law enforcement requirements;</li>
            <li>to protect, establish, or exercise our legal rights or defend against legal claims, including to collect a debt; to comply with a subpoena, court order, legal process, or other legal requirement;</li>
            <li>or when we believe in good faith that such disclosure is necessary to comply with the law, prevent imminent physical harm or financial loss, or investigate, prevent, or take action regarding illegal activities, suspected fraud, threats to our property, or violations of Youmedico Terms & Conditions.</li>
            <p className="alternate">Please note that above mentioned lists are not exhaustive. We will record all purposes for which we process your personal data.</p>
          </ul>

          <h4>Where do we store and process personal data?</h4>
          <p>
            We adopted all statutory requirements with regards to our
            obligations as a data controller and/or processor under GDPR to
            provide you with adequate level of data protection. You should be
            aware that Youmedico is a company registered and acting under the
            law of Sweden and directly falls under the regulation of the EU on
            the protection of personal data, including General Data Protection
            Regulation (GDPR) - Regulation (EU) 2016/679 of the European
            Parliament and of the Council of 27 April 2016.
          </p>
          <p>
            We provide adequate protection of personal data under GDPR, and
            Youmedico has implemented all appropriate safeguards to guarantee
            our Users the compliance with the highest personal data protection
            standards and principles under the GDPR and other appropriate
            international regulation.
          </p>
          <p className="text-center">
            <strong>
              BY GRANTING YOUR CONSENT TO THE USE OF YOUPAGROUP, YOU EXPLICITLY
              CONSENTED THAT YOU DULY INFORMED AND AGREED HEREOF THAT YOUR
              PERSONAL DATA WILL BE STORED AND PROCESSED BY Youmedico IN A
              MANNER AS IT IS DESCRIBED BY THIS PRIVACY POLICY.
            </strong>
          </p>

          <h4>Use of cookies and other technologies</h4>
          <p>
            We use cookie identifiers and other similar tracking technologies to
            allow you to take advantage of some of Youmedico's essential
            features. Cookies can be small data file sent from a server to your
            web browser that is stored in your browser cache. Cookies on our
            website are used to:
          </p>
          <ul>
            <li>Recognizing your browser or device for security purposes and
            preventing fraudulent activity. </li>
            <li>Recognizing you when you sign-in to use our website for
            cross-device tracking. This allows us to recognize your activity on
            Youmedico across multiple devices and browsers and track of items
            stored in your shopping basket. </li>
            <li>Tracking of your activity in
            Youmedico allows us to discover what adverts you click on our
            website in order to show you related content or to limit the number
            of times of displaying such ads. </li>
            <li>Attribution tracking allows us to estimate what advertising or
            marketing source you followed to come to Youmedico, and/or to
            determine what marketing source should get credit for actions like a
            visit or a purchase. </li>
            <li>Researching how Users interact with our services (for example,
            statistical reports) allows us to improve the quality of Youmedico
            website </li>
          </ul>
          <p>
            Youmedico retains the right to cooperate with any third party
            (including search engines, providers of measurement and analytics
            services, social media networks, and advertising companies) which
            may use its own tracking technologies to provide certain services or
            features, including targeted online marketing techniques such as
            attribution tracking, remarketing, and cross-device tracking. Such
            third parties may provide us with some statistical information about
            you (for example, your interests, information on your devices etc.),
            which help us to improve the adverts shown each time you visit
            Youmedico.
            <br />
            Youmedico may include widgets from third-party websites and social
            networks, and third parties may choose to include Youmedico widgets
            on their websites. Youmedico, as well as the third party, may
            receive analytics from those widgets that may be used to enhance our
            data, to improve our services, or for marketing purposes. You can
            manage your privacy preferences for these widgets via your account
            with the third party.
            <br />
            Almost all browsers (Internet Explorer, Firefox, Chrome etc.) can
            suggest how to prevent your browser from accepting new cookies, how
            to have the browser notify you when you receive a new cookie, or how
            to disable cookies altogether. If you do not wish such information
            to be collected about you, please refer to the instructions of your
            browser.
            <br />
            Also you can disable or delete similar data used by browser add-ons,
            such as Flash cookies, by changing the add-on's settings or visiting
            the Web site of its manufacturer.
            <br />
          </p>
          <p className="text-center">
            <strong>
            PLEASE NOTE THAT IF YOU REJECT OR BLOCK ALL COOKIES IN YOUR BROWSER SETTINGS, YOU WILL NOT BE ABLE TO TAKE FULL ADVANTAGE OF Youmedico’S SERVICES AS SOME COOKIES ARE NECESSARY FOR THE SITE TO FUNCTION PROPERLY.
            </strong>
          </p>

          <h4>When do we share personal data?</h4>
          <p>
            We always treat personal data confidentiality and do not disclose
            your personal data to other clients, organizations or individuals,
            if such disclosure is not necessary for provision of Youmedico
            services or conducting our business operations with you, as it is
            outlined in the purposes for processing of such data.
          </p>
          <p>
          In addition to the disclosures outlined within this Privacy Policy,
            we may disclose information about you:
          </p>
          <ul>
            <li>to the extent that we are required to do so by law;</li>
            <li>in connection with any legal proceedings or prospective legal
            proceedings;</li>
            <li>in order to establish, exercise or defend our legal rights -
            including providing information to others for the purposes of fraud
            prevention and reducing credit risk;</li>
            <li>to Youpal AB as a parent company; and</li>
            <li>to third-parties in case of traffic measurement and/or advertising
            provider.</li>
          </ul>
          <p className="text-center">
            <strong>
            WE WILL NEVER SELL YOUR PERSONAL INFORMATION TO ANY THIRD PARTY.
            </strong>
          </p>
          <p>
            In addition, we may share data with trusted partners to contact you
            based on your request to receive such communications, help us
            perform statistical analysis, or provide User support. Such third
            parties are prohibited from using your personal data except for
            these purposes, and they are required to maintain the
            confidentiality of your information. If you do not want us to share
            your personal data with these companies, contact us.
          </p>
          <p>
            We employ other companies and people to provide services to visitors
            of our website, our Users, and users of the Youmedico services and
            may need to share your information with them to provide information,
            products or services to you. Examples may include analysing data,
            processing credit card payments, and providing User service. In all
            cases where we share your information with such agents, we
            explicitly require the agent to acknowledge and adhere to our
            privacy and User data handling policies.
          </p>

          <h4>How do we secure personal data?</h4>
          <p>
            We will use all reasonable measures to protect your Personal Data
            from unauthorized access, alteration, disclosure or removal. We are
            equipped with the means of security and protection of your Personal
            Data.
            <br />
            Immediate access to your Personal Data is allowed only to us and
            persons authorized by us and involved in the maintenance of the
            Service. Such persons will strictly keep confidentiality of and
            prevent unauthorized third-party access to your Personal Data.
          </p>

          <h4>How long do we keep your personal data for?</h4>
          <p>
            We keep the information no longer than it is reasonably necessary.
            If you do not want to use our services, you can immediately delete
            your personal data by contacting us.
            <br />
            We reserve the right to keep information that is reasonably
            necessary when it is required to comply with applicable tax/revenue
            laws, resolve disputes, and enforce our agreements. We will delete
            this information as soon as such data become no needed but no later
            than 2 years.
          </p>

          <h4>Your rights in relation to personal data</h4>
          <p>
            We welcome that our Users have control over their own information.
            Youmedico gives you a choice of providing, editing or removing
            personal data, as well as choices about how we contact you. You may
            also remove certain optional information that you no longer wish to
            be publicly visible through the website, such as your full name.
            <br />
            You always have access to your personal information on Youmedico. If
            they are incorrect, we give a way to quickly change or delete them.
            This does not apply to cases where information is required for
            justified commercial or legal purposes.
            <br />
            We appreciate our clients, but we also appreciate their freedom of
            choice. You can withdraw your consent to our processing of your
            information and your use of the Website. You are able to do it at
            any time by sending an email on oleh.ch@youpal.se to request that
            your personal information be deleted.
            <br />
            You have the right to restrict the processing of your personal data
            for some purposes. When you have any objection to processing your
            personal data, you can do so by sending an email on
            oleh.ch@youpal.se.
            <br />
            We are able to provide you with a copy of all the personal data that
            we have regarding you, in electronic format. You always have the
            ability and capacity to export your personal data collected or
            stored digitally concerning you as our User by sending your request
            via email on oleh.ch@youpal.se.
            <br />
            If you reasonably believe that we are violating our responsibilities
            to protect your privacy, you have a right to lodge a complaint with
            a relevant supervisory authority
          </p>

          <h4>Information About minors</h4>
          <p>
            The website is not intended for or targeted at minors under 18, and
            we do not knowingly or intentionally collect information about
            minors under 18. If you believe that we have collected information
            about a minor under 18, please contact us at oleh.ch@youpal.se, so
            that we may delete the information.
          </p>

          <h4>Linking to other websites / third party content</h4>
          <p>
            Our website may provide links to other websites. We do not control,
            and are not responsible for, the content or practices of these other
            websites. Our provision of such links does not constitute our
            endorsement of these other websites, their content, their owners, or
            their practices. This Privacy Policy does not apply to these other
            websites, which are subject to any privacy and other policies they
            may have.
          </p>

          <h4>Privacy Policy Changes</h4>
          <p>
            We may update this Privacy Policy from time to time by posting a new
            version online. You should check this page occasionally to review
            any changes. If we make any material changes we will notify you by
            posting the revised Privacy Policy on our website and providing
            notice to your email. This helps you to always be aware of what
            information we collect, how we use it and under what circumstances,
            if any, it is disclosed. Your continued use of the website and
            Youmedico services, and/or continued provision of personal data to
            us you will be subject to the terms of the then-current Privacy
            Policy.
          </p>

          <h4>Contact us</h4>
          <p>
            Please contact us if you have any questions regarding this Privacy Policy. <br />
            Post address: <br />
            Youpal SL <br />
            CALLE VIRGEN DE LA LUZ <br />
            S/N 11140 <br />
            CONIL DE LA FRONTERA <br />
            Spain <br />
            Email address: <br />
            oleh.ch@youpal.se

          </p>
        </div>
      </div>
    </section>
  </div>
)

export default PrivacyPage
